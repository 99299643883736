@font-face {
    font-family: "tt_commons";
    src: url("../fonts/tt_commons/tt_commons_regular-webfont.woff2")
        format("woff2"),
      url("../fonts/tt_commons/tt_commons_regular-webfont.woff2")
        format("woff");
  }



  .footer-container {
    max-width: 1170px;
    margin: auto;
  }
  .footer-row {
    display: flex;
    flex-wrap: wrap;
  }
  ul {
    list-style: none;
  }
  .footer {
    background-color: #051d23;
    padding: 70px 0;
    margin-top: 200px;
  }
  .footer-col {
    width: 25%;
    padding: 0 15px;
  }
  .footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 35px;
    font-weight: 500;
  }
  
  .footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  .footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
  }
  .footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
  }
  .footer-col .social-links {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links a:hover {
    color: #051d23;
    background-color: #ffffff;
  }
  
  .footer-social-icons {
    font-size: 35px;
    color: white;
  }
  
  /*responsive*/
  @media (max-width: 767px) {
    .footer-col {
      width: 50%;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 574px) {
    .footer-col {
      width: 100%;
    }
  }

  .blog-header{
    text-decoration: none;
    color: black;
  }
  .blog-header :hover{
    text-decoration: underline;
  
  }
  

 

  .react-datepicker__input-container input {
      padding: 20px;
      display: block;
      width: 100%;
      border-radius: 6px;
      border: solid 0.1px #dce0e4;
      color: #637381;
      font-size: 16px;
  }

  .react-datepicker__input-container input:hover {
    border: solid 0.1px black;
  }


  .MuiCard-root{
    display: flex;
    flex-direction: column;
  }
  .MuiCardActions-root{
     margin-top: auto;
  }


.helper-text-custom{
  color: #637381;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.header-background{
  background-color: #e6e4eb;
}

.companiesWeWorkWith {
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.8s ease-in-out;
}

.companiesWeWorkWith:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
}


.blog-description{
  line-height: 1.75;
    font-size: 1rem;
    font-weight: 400;
    color: #212B36;
}

.text-center-on-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}