
.w-50{
  width: 50%;
}
.w-100{
  width: 100%;
}

.no-margin-or-padding{
  margin: 0px;
  padding: 0px;
}

.customButton{
  font-size: 15px;
  color: #014459;
  text-decoration: none;
  font-weight: 600;
}
  
  /* some colors */
  .color-black {
    color: black
  }
  
  .color-white {
    color: white
  }
  
  .bg-black {
    background-color: black
  }
  
  .bg-white {
    background-color: white
  }
  
  
  
  
  
  /* GENERIC CSS */
  
  .pointer {
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }
  
  .show {
    display: block;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-end{
    text-align: end;
  }
  
  
  .module-player-container{
    height: 83vh;
  }
  
  /* Flex */
  .flex {
    display: flex;
  }
  
  .row {
    flex-direction: row;
  }
  
  .column {
    flex-direction: column;
  }
  
  .justify-start {
    justify-content: flex-start;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .justify-end {
    justify-content: flex-end;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  .align-start {
    align-items: flex-start;
  }
  
  .align-center {
    align-items: center;
  }
  
  .align-end {
    align-items: flex-end;
  }
  
  .rounded {
    border-radius: 4px;
  }
  
  .rounded-sm {
    border-radius: 2px;
  }
  
  .rounded-lg {
    border-radius: 8px;
  }

  .rounded-xlg {
    border-radius: 16px;
  }
  
  .rounded-circle {
    border-radius: 50%;
  }
  
  .rounded-pill {
    border-radius: 50rem;
  }
  
  .rounded-0 {
    border-radius: 0;
  }
  
  
  
  .font-weight-100 {
    font-weight: 100;
  }
  
  .font-weight-200 {
    font-weight: 200;
  }
  
  .font-weight-300 {
    font-weight: 300;
  }
  
  .font-weight-400 {
    font-weight: 400;
  }
  
  .font-weight-500 {
    font-weight: 500;
  }
  
  .font-weight-600 {
    font-weight: 600;
  }
  
  .font-weight-700 {
    font-weight: 700;
  }
  
  .font-weight-800 {
    font-weight: 800;
  }
  
  .font-weight-900 {
    font-weight: 900;
  }
  
  .no-text-decoration {
    text-decoration: none;
  }
  
  /* margin left */
  .ml-0 {
    margin-left: 0px;
  }
  
  .ml-1 {
    margin-left: 5px;
  }
  
  .ml-2 {
    margin-left: 10px;
  }
  
  .ml-3 {
    margin-left: 15px;
  }
  
  .ml-4 {
    margin-left: 20px;
  }
  
  .ml-5 {
    margin-left: 25px;
  }
  
  .ml-6 {
    margin-left: 30px;
  }
  
  .ml-7 {
    margin-left: 35px;
  }
  
  .ml-8 {
    margin-left: 40px;
  }
  
  .ml-9 {
    margin-left: 45px;
  }
  
  .ml-10 {
    margin-left: 50px;
  }
  
  .mr-0 {
    margin-right: 0px;
  }
  
  .mr-1 {
    margin-right: 5px;
  }
  
  .mr-2 {
    margin-right: 10px;
  }
  
  .mr-3 {
    margin-right: 15px;
  }
  
  .mr-4 {
    margin-right: 20px;
  }
  
  .mr-5 {
    margin-right: 25px;
  }
  
  .mr-6 {
    margin-right: 30px;
  }
  
  .mr-7 {
    margin-right: 35px;
  }
  
  .mr-8 {
    margin-right: 40px;
  }
  
  .mr-9 {
    margin-right: 45px;
  }
  
  .mr-10 {
    margin-right: 50px;
  }
  
  .mt-0 {
    margin-top: 0px;
  }
  
  .mt-1 {
    margin-top: 5px;
  }
  
  .mt-2 {
    margin-top: 10px;
  }
  
  .mt-3 {
    margin-top: 15px;
  }
  
  .mt-4 {
    margin-top: 20px;
  }
  
  .mt-5 {
    margin-top: 25px;
  }
  
  .mt-6 {
    margin-top: 30px;
  }
  
  .mt-7 {
    margin-top: 35px;
  }
  
  .mt-8 {
    margin-top: 40px;
  }
  
  .mt-9 {
    margin-top: 45px;
  }
  
  .mt-10 {
    margin-top: 50px;
  }
  
  /* Paddings */
  
  .mb-0 {
    margin-bottom: 0px;
  }
  
  .mb-1 {
    margin-bottom: 5px;
  }
  
  .mb-2 {
    margin-bottom: 10px;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .mb-4 {
    margin-bottom: 20px;
  }
  
  .mb-5 {
    margin-bottom: 25px;
  }
  
  .mb-6 {
    margin-bottom: 30px;
  }
  
  .mb-7 {
    margin-bottom: 35px;
  }
  
  .mb-8 {
    margin-bottom: 40px;
  }
  
  .mb-9 {
    margin-bottom: 45px;
  }
  
  .mb-10 {
    margin-bottom: 50px;
  }
  
  .pl-0 {
    padding-left: 0px;
  }
  
  .pl-1 {
    padding-left: 5px;
  }
  
  .pl-2 {
    padding-left: 10px;
  }
  
  .pl-3 {
    padding-left: 15px;
  }
  
  .pl-4 {
    padding-left: 20px;
  }
  
  .pl-5 {
    padding-left: 25px;
  }
  
  .pl-6 {
    padding-left: 30px;
  }
  .pl-7 {
    padding-left: 35px;
  }
  .pl-8 {
    padding-left: 40px;
  }
  .pl-9 {
    padding-left: 45px;
  }
  .pl-10 {
    padding-left: 50px !important;
  }
  
  .pr-0 {
    padding-right: 0px;
  }
  
  .pr-1 {
    padding-right: 5px;
  }
  
  .pr-2 {
    padding-right: 10px;
  }
  
  .pr-3 {
    padding-right: 15px;
  }
  
  .pr-4 {
    padding-right: 20px;
  }
  
  .pr-5 {
    padding-right: 25px;
  }
  .pr-6 {
    padding-right: 30px;
  }
  
  .pr-7 {
    padding-right: 35px;
  }
  
  .pr-8 {
    padding-right: 40px;
  }
  
  .pr-9 {
    padding-right: 45px;
  }
  
  .pr-10 {
    padding-right: 50px;
  }
  
  
  .pt-0 {
    padding-top: 0px;
  }
  
  .pt-1 {
    padding-top: 5px;
  }
  
  .pt-2 {
    padding-top: 10px;
  }
  
  .pt-3 {
    padding-top: 15px;
  }

  .pt-4 {
    padding-top: 20px;
  }
  .pt-5 {
    padding-top: 25px;
  }
  .pt-6 {
    padding-top: 30px;
  }
  .pt-7 {
    padding-top: 35px;
  }
  .pt-8 {
    padding-top: 40px;
  }
  .pt-9 {
    padding-top: 45px;
  }
  .pt-10 {
    padding-top: 50px;
  }

  .pb-1 {
    padding-bottom: 5px;
  }
  .pb-2 {
    padding-bottom: 10px;
  }
  .pb-3 {
    padding-bottom: 15px;
  }
  .pb-4 {
    padding-bottom: 20px;
  }
  .pb-5 {
    padding-bottom: 25px;
  }
  .pb-6 {
    padding-bottom: 30px;
  }
  .pb-7 {
    padding-bottom: 35px;
  }
  .pb-8 {
    padding-bottom: 40px;
  }
  .pb-9 {
    padding-bottom: 45px;
  }
  .pb-10 {
    padding-bottom: 50px;
  }


  
  /* From 11-20 */
  .mt-11 {
    margin-top: 55px;
  }
  
  .mt-12 {
    margin-top: 60px;
  }
  
  .mt-13 {
    margin-top: 65px;
  }
  
  .mt-14 {
    margin-top: 70px;
  }
  
  .mt-15 {
    margin-top: 75px;
  }
  
  .mt-16 {
    margin-top: 80px;
  }
  
  .mt-17 {
    margin-top: 85px;
  }
  
  .mt-18 {
    margin-top: 90px;
  }
  
  .mt-19 {
    margin-top: 95px;
  }
  
  .mt-20 {
    margin-top: 100px;
  }
  
  .mb-11 {
    margin-bottom: 55px;
  }
  
  .mb-12 {
    margin-bottom: 60px;
  }
  
  .mb-13 {
    margin-bottom: 65px;
  }
  
  .mb-14 {
    margin-bottom: 70px;
  }
  
  .mb-15 {
    margin-bottom: 75px;
  }
  
  .mb-16 {
    margin-bottom: 80px;
  }
  
  .mb-17 {
    margin-bottom: 85px;
  }
  
  .mb-18 {
    margin-bottom: 90px;
  }
  
  .mb-19 {
    margin-bottom: 95px;
  }
  
  .mb-20 {
    margin-bottom: 100px;
  }
  
  .ml-11 {
    margin-left: 55px;
  }
  
  .ml-12 {
    margin-left: 60px;
  }
  
  .ml-13 {
    margin-left: 65px;
  }
  
  .ml-14 {
    margin-left: 70px;
  }
  
  .ml-15 {
    margin-left: 75px;
  }
  
  .ml-16 {
    margin-left: 80px;
  }
  
  .ml-17 {
    margin-left: 85px;
  }
  
  .ml-18 {
    margin-left: 90px;
  }
  
  .ml-19 {
    margin-left: 95px;
  }
  
  .ml-20 {
    margin-left: 100px;
  }
  
  .mr-11 {
    margin-right: 55px;
  }
  
  .mr-12 {
    margin-right: 60px;
  }
  
  .mr-13 {
    margin-right: 65px;
  }
  
  .mr-14 {
    margin-right: 70px;
  }
  
  .mr-15 {
    margin-right: 75px;
  }
  
  .mr-16 {
    margin-right: 80px;
  }
  
  .mr-17 {
    margin-right: 85px;
  }
  
  .mr-18 {
    margin-right: 90px;
  }
  
  .mr-19 {
    margin-right: 95px;
  }
  
  .mr-20 {
    margin-right: 100px;
  }
  
  .m-1 {
    margin: 5px;
  }
  
  .m-2 {
    margin: 10px;
  }
  
  .m-3 {
    margin: 15px;
  }
  
  .m-4 {
    margin: 20px;
  }
  
  .m-5 {
    margin: 25px;
  }
  
  .m-6 {
    margin: 30px;
  }
  
  .m-7 {
    margin: 35px;
  }
  
  .m-8 {
    margin: 40px;
  }
  
  .m-9 {
    margin: 45px;
  }
  
  .m-10 {
    margin: 50px;
  }

  .m-11 {
    margin: 55px;
  }

  .m-12 {
    margin: 60px;
  }

  .m-13 {
    margin: 65px;
  }

  .m-14 {
    margin: 70px;
  }

  .m-15 {
    margin: 75px;
  }
  
  .p-1 {
    padding: 5px;
  }
  
  .p-2 {
    padding: 10px;
  }
  
  .p-3 {
    padding: 15px;
  }
  
  .p-4 {
    padding: 20px;
  }
  
  .p-5 {
    padding: 25px;
  }
  
  .p-6 {
    padding: 30px;
  }
  
  .p-7 {
    padding: 35px;
  }
  
  .p-8 {
    padding: 40px;
  }
  
  .p-9 {
    padding: 45px;
  }
  
  .p-10 {
    padding: 50px;
  }

  .p-11 {
    padding: 55px;
  }

  .p-12 {
    padding:60px;
  }

  .p-13 {
    padding: 65px;
  }

  .p-14 {
    padding: 70px;
  }

  .p-15 {
    padding: 75px;
  }

  .p-16 {
    padding: 80px;
  }
  .p-17 {
    padding: 85px;
  }
  .p-18 {
    padding: 90px;
  }